import React, { useEffect, useState } from 'react';
import '../App.css';
import CircularGauge from '../display/CircularGauge';
import {
    BoxContainer,
    Container1,
    Container2,
    Container3,
    ResultContainer,
    Title,
    Risk,
    Items,
    Result,
    MllmTextStyle, TypingText, Container
} from "../styles/ResultStyles";
import useApi from "../backend/users";
import BiomarkerResults from "../display/biomarkers";
import { EmotionWrapper, EmotionTitle, EmotionRow, EmotionContainer, EmotionItem, EmotionLabel, EmotionValue } from "../styles/EmotionStyles";

const DisplayResult = ({ vbmResult, s2tResult, fileName, lang }) => {
    const mentalResults = vbmResult.content?.result || {};
    const dementiaResult = mentalResults.dementia || {};
    const depressionResult = mentalResults.depression || {};
    const dementiaRisk = mentalResults.dementia.dementia || {};
    const depressionRisk = mentalResults.depression.negative || {};
    const emotionResult = mentalResults.emotion || {};

    const text = s2tResult.content?.result.text || '';

    const { mllm } = useApi();

    const [mllmCalled, setMllmCalled] = useState(false);
    const [mllmResult, setMllmResult] = useState(null);
    const [showAnimation, setShowAnimation] = useState(true); // 애니메이션을 표시할지 여부를 결정하는 상태


    const getDementiaResult = () => {
        const bestResult = Object.keys(dementiaResult).reduce((a, b) => dementiaResult[a] > dementiaResult[b] ? a : b, '');
        return bestResult === 'control' ? 'Normal' : 'Dementia';
    };

    const getDepressionResult = () => {
        const bestResult = Object.keys(depressionResult).reduce((a, b) => depressionResult[a] > depressionResult[b] ? a : b, '');
        return bestResult === 'positive' ? 'Normal' : 'Depression';
    };

    const dementiaResultText = getDementiaResult();
    const depressionResultText = getDepressionResult();

    useEffect(() => {
        if (dementiaResultText && depressionResultText && !mllmCalled) {
            console.log('mllm 호출');
            const status = { dementia_status: dementiaResultText, depression_status: depressionResultText, fileName: fileName, lang: lang };
            mllm(status).then(result => {
                setMllmResult(result);
                setMllmCalled(true);
                console.log('mllm:', result);
                setShowAnimation(false);
            });
        }
    }, [dementiaResultText, depressionResultText, fileName, mllm, mllmCalled]);

    return (
        <Container>
            <BoxContainer>
                <ResultContainer>
                    <Container1>
                        <Title>Items</Title>
                        <Items>Dementia</Items>
                        <Items>Depression</Items>
                    </Container1>
                    <Container2>
                        <Title>Result</Title>
                        <Result>
                            {dementiaResultText}
                        </Result>
                        <Result>
                            {depressionResultText}
                        </Result>
                    </Container2>
                    <Container3>
                        <Title>Risk Probability</Title>
                        <Risk>{dementiaRisk.toString()}</Risk>
                        <Risk>{depressionRisk.toString()}</Risk>
                    </Container3>
                </ResultContainer>
                <Title>Speech recognition</Title>
                <Container1 style={{marginBottom: '20px', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{ width: '96%', backgroundColor: 'white', padding: '20px', borderRadius: '10px', marginTop: '10px', marginBottom: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', fontSize: '14px', fontFamily: 'DM Sans,sans-serif' }}>
                    {text}
                </div>
                </Container1>
                <Container1>
                    <Title>Biomarkers</Title>
                    <BiomarkerResults vbmResult={vbmResult} s2tResult={s2tResult} />
                </Container1>
                <EmotionWrapper>
                    <EmotionTitle>Emotion</EmotionTitle>
                    <EmotionRow>
                        <EmotionContainer>
                            <EmotionItem>
                                <EmotionLabel>Anger</EmotionLabel>
                                <EmotionValue>{emotionResult.ANGRY}</EmotionValue>
                            </EmotionItem>
                            <CircularGauge value={emotionResult.ANGRY} type={'angry'} />
                        </EmotionContainer>
                        <EmotionContainer>
                            <EmotionItem>
                                <EmotionLabel>Happiness</EmotionLabel>
                                <EmotionValue>{emotionResult.HAPPINESS}</EmotionValue>
                            </EmotionItem>
                            <CircularGauge value={emotionResult.HAPPINESS} type={'happy'} />
                        </EmotionContainer>
                        <EmotionContainer>
                            <EmotionItem>
                                <EmotionLabel>Sadness</EmotionLabel>
                                <EmotionValue>{emotionResult.SADNESS}</EmotionValue>
                            </EmotionItem>
                            <CircularGauge value={emotionResult.SADNESS} type={'sad'} />
                        </EmotionContainer>
                        <EmotionContainer>
                            <EmotionItem>
                                <EmotionLabel>Neutral</EmotionLabel>
                                <EmotionValue>{emotionResult.NEUTRAL}</EmotionValue>
                            </EmotionItem>
                            <CircularGauge value={emotionResult.NEUTRAL} type={'neutral'} />
                        </EmotionContainer>
                    </EmotionRow>
                </EmotionWrapper>
            </BoxContainer>
            <BoxContainer>
                <Title>Mental Recommendation</Title>
                <MllmTextStyle>
                    {showAnimation ? (
                            <TypingText>Mllm is being processed...</TypingText>
                    ) : (
                        // mllm 결과가 도착하면 결과를 표시
                        <div>{mllmResult.result}</div>
                    )}
                </MllmTextStyle>
            </BoxContainer>
        </Container>
    );
};

export default DisplayResult;
